import React from 'react';
import { Link } from 'react-router-dom';
import routesConfig from '../routesConfig';
import './NavBar.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-section">
        {routesConfig.filter(route => route.category !== 'tutorial').map(({ path, name }) => (
          <Link key={path} to={path}>{name}</Link>
        ))}
      </div>
      {/* Theme toggle button or other navbar actions */}
    </nav>
  );
};

export default NavBar;
