import React from 'react';
import Gallery from './components/Gallery';
import Tutorial from './components/Tutorial';
import { Navigate } from 'react-router-dom';

const routesConfig = [
  // Navigate to "/tutorial1" as a fixed route
  {
    path: '/',
    name: 'Home',
    element: <Tutorial markdownFile="tutorial1.md" />
  },

  // Other routes not part of the tutorials group remain unchanged
  { path: '/folder1', name: '3d', element: <Gallery folder='folder1' /> },
  { path: '/folder2', name: '4d', element: <Gallery folder='folder2' /> },
  { path: '/folder3', name: '5d', element: <Gallery folder='folder3' /> },
  { path: '/folder4', name: '6d', element: <Gallery folder='folder4' /> },
  { path: '/folder5', name: '7d', element: <Gallery folder='folder5' /> },
  { path: '/folder6', name: '8d', element: <Gallery folder='folder6' /> },
  { path: '/folder7', name: '9d', element: <Gallery folder='folder7' /> },
  
  // Fixed route for tutorial2
  {
    path: '/tutorial2',
    name: 'Tutorial',
    element: <Tutorial markdownFile="tutorial2.md" />
  },

];

export default routesConfig;