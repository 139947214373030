import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ImageViewer = ({ theme }) => {
  const location = useLocation();
  const imageUrl = new URLSearchParams(location.search).get('url');
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const [brightness, setBrightness] = useState(100);
  const [blur, setBlur] = useState(0);
  const [isInverted, setIsInverted] = useState(false); // State for image inversion
  const imageRef = useRef(null);

  const viewerStyle = {
    textAlign: 'center',
    marginTop: '20px',
    backgroundColor: theme === 'dark' ? '#333' : '#f0f0f0',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    color: theme === 'dark' ? 'white' : 'black',
  };

  const buttonStyle = {
    margin: '5px',
    borderRadius: '5px',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
    backgroundColor: theme === 'dark' ? '#b28e00' : '#0056b3',
    color: 'white',
    fontWeight: 'bold',
  };

  const rotateImage = (direction) => {
    setRotationDegrees((prevDegrees) => prevDegrees + (direction === 'right' ? 90 : -90));
  };

  const saveImage = async () => {
    if (!imageRef.current) return;
  
    const offScreenImage = new Image();
    offScreenImage.crossOrigin = "anonymous";
    offScreenImage.src = imageUrl;
  
    await new Promise((resolve, reject) => {
      offScreenImage.onload = resolve;
      offScreenImage.onerror = reject;
    });
  
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const originalWidth = offScreenImage.width;
    const originalHeight = offScreenImage.height;
  
    if (rotationDegrees % 180 === 0) {
      canvas.width = originalWidth;
      canvas.height = originalHeight;
    } else {
      canvas.width = originalHeight;
      canvas.height = originalWidth;
    }
  
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotationDegrees * Math.PI) / 180);
  
    // Set the filters for brightness, blur, and inversion together
    const canvasBrightness = brightness / 100;
    ctx.filter = `brightness(${canvasBrightness}) blur(${blur}px)${isInverted ? ' invert(100%)' : ''}`;
  
    // Draw the image with the filters applied
    ctx.drawImage(offScreenImage, -originalWidth / 2, -originalHeight / 2, originalWidth, originalHeight);
  
    // No need to reset the filter to 'none' here as we want to maintain the filter effects for saving
  
    canvas.toBlob((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const timestamp = new Date().toISOString().replace(/[^0-9]/g, '');
      const randomPart = Math.floor(Math.random() * 1000);
      link.download = `transformed-image-${timestamp}-${randomPart}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 'image/png');
  };
  
  

  const invertImage = () => {
    setIsInverted(!isInverted);
  };

  return (
    <div style={viewerStyle}>
      <div>
        <button style={buttonStyle} onClick={() => rotateImage('left')}>Rotate Left</button>
        <button style={buttonStyle} onClick={() => rotateImage('right')}>Rotate Right</button>
        <button style={buttonStyle} onClick={invertImage}>Inverse</button> {/* Add inverse button */}
        <button style={buttonStyle} onClick={saveImage}>Save Image</button>
        <div>
          <label htmlFor="brightness">Brightness:</label>
          <input
            type="range"
            id="brightness"
            name="brightness"
            min="0"
            max="200"
            value={brightness}
            onChange={(e) => setBrightness(e.target.value)}
            style={{ marginLeft: '10px' }}
          />
          <label htmlFor="blur" style={{ marginLeft: '20px' }}>Blur:</label>
          <input
            type="range"
            id="blur"
            name="blur"
            min="0"
            max="10"
            value={blur}
            onChange={(e) => setBlur(e.target.value)}
            style={{ marginLeft: '10px' }}
          />
        </div>
      </div>
      <img
        ref={imageRef}
        src={imageUrl}
        alt="Full Size"
        crossOrigin="anonymous"
        style={{
          maxHeight: '80vh',
          maxWidth: '100%',
          transform: `rotate(${rotationDegrees}deg)`,
          transition: 'transform 0.2s',
          filter: `brightness(${brightness}%) blur(${blur}px)${isInverted ? ' invert(100%)' : ''}`,
          marginTop: '20px',
          borderRadius: '4px',
        }}
      />
    </div>
  );
};

export default ImageViewer;
