import React, { useState, useEffect } from 'react';
// Set NODE_ENV to a valid value
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const Gallery = ({ folder }) => {
    const [images, setImages] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const batchSize = 50; // Adjust based on your needs

    useEffect(() => {
        console.log(`Effect running for folder: ${folder}`);
        // Reset state when folder changes
        setImages([]);
        setHasMore(true);
        setStartIndex(0);
        fetchImages(0, batchSize); // Fetch the first batch of images
    }, [folder]);

    const fetchImages = async (start, limit) => {
        try {
            const response = await axios.get('https://eigenart.gallery/api/images', {
                params: { folder, start, limit }
            });
            // Assuming the Flask API returns the full URL ready to use
            const fetchedImages = response.data.map(img => ({
                src: img.src, // Use the src directly without prepending the base URL
                alt: img.alt
            }));

            if (fetchedImages.length === 0 || fetchedImages.length < limit) {
                setHasMore(false); // No more images to load, or last batch is smaller than limit
            }

            setImages(prevImages => [...prevImages, ...fetchedImages]);
            setStartIndex(start + limit); // Update start index for the next batch
        } catch (error) {
            console.error('Failed to fetch images:', error);
            setHasMore(false);
        }
    };

    const loadMoreImages = () => {
        fetchImages(startIndex, batchSize);
    };

    // Function to handle image click, navigating to the ImageViewer component
    const handleImageClick = (imageUrl) => {
       window.open(`/view-image?url=${encodeURIComponent(imageUrl)}`, '_blank');
    };

    return (
        <InfiniteScroll
            dataLength={images.length}
            next={loadMoreImages}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
                <p style={{ textAlign: 'center' }}>
                    <b>You have seen all the images.</b>
                </p>
            }
        >
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center',marginTop:'50px' }}>
                {images.map((image, index) => (
                    // Update here: Added onClick handler to navigate to the ImageViewer
                    <img key={index} src={image.src} alt={image.alt} 
                         style={{ width: '220px', margin: '20px', cursor: 'pointer' }} 
                         onClick={() => handleImageClick(image.src)} />
                ))}
            </div>
        </InfiniteScroll>
    );
};

export default Gallery;
