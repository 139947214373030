import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkMathPlugin from 'remark-math';
import RehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import 'katex/dist/katex.min.css';

const Tutorial = ({ markdownFile }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/markdown/${markdownFile}`)
      .then(response => response.text())
      .then(text => {
        setContent(text);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error loading markdown file:", error);
        setLoading(false);
      });
  }, [markdownFile]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <ReactMarkdown 
        children={content}
        remarkPlugins={[RemarkMathPlugin, remarkGfm]}
        rehypePlugins={[RehypeKatex, rehypeSlug, rehypeAutolinkHeadings]}
        components={{
          a: ({node, ...props}) => <a {...props} onClick={(e) => {
            const href = props.href;
            if (href.startsWith('#')) {
              e.preventDefault();
              const id = href.slice(1);
              const element = document.getElementById(id);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }}/>
        }}
      />
    </div>
  );
};

export default Tutorial;