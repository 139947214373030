import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import routesConfig from './routesConfig';
import ImageViewer from './components/ImageViewer';

import './App.css';

function App() {
  const [theme, setTheme] = useState('dark');

  const toggleTheme = () => {
    setTheme(currentTheme => (currentTheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <div className={`app ${theme}-theme`}>
      <Router>
        <NavBar />
        <div className="main-content">
          <button onClick={toggleTheme} className="theme-toggle">
            {theme === 'dark' ? '☀️' : '🌙'}
          </button>

          <Routes>
            {routesConfig.map((route) => (
              <Route key={route.path} path={route.path} element={React.cloneElement(route.element, { theme })} />
            ))}
            <Route path="/view-image" element={<ImageViewer theme={theme} />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;